import React, {
  useState,
  useEffect,
  lazy,
  Fragment,
} from "react";
const Pagination = lazy(() => import("../common/Pagination/Pagination"));

import { Crumb, Product } from "../../types";
const Card = lazy(() => import("./Card"));

const Breadcrumb = lazy(() => import("../common/Breadcrumb"));
import "./CategoryStyle.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import { getQueryString } from "../../utils/helper";

interface ProductListProps {
  pageContext: any;
  categoryName: string;
  products: Product[];
  additionalInformation: category_additional_info
}

interface category_additional_info {
  banner_bg_1: IAdditionalInfoImage;
  banner_mobile_bg_1: IAdditionalInfoImage;
  banner_bg_2: IAdditionalInfoImage;
  banner_mobile_bg_2: IAdditionalInfoImage;
  banner_image: IAdditionalInfoImage;
  banner_text_1: string;
  banner_text_2: string;
  banner_text_3: string;
  bannerText3?: string[];
  thumbnail_1: IAdditionalInfoImage;
  thumbnail_2: IAdditionalInfoImage;
  category_rich_description: string;
}

interface IAdditionalInfoImage {
  node: {
    id: number;
    publicUrl: string;
    altText: string;
  }
}

interface CategoryItem {
  meta_key: string;
  meta_value: string;
  image_url?: string;
}

// Define your state type
interface AdditionalInfo {
  [key: string]: string | string[];
}


const List = ({ pageContext, categoryName, products, additionalInformation }: ProductListProps) => {
  const ITEMS_PER_PAGE = Number(process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE);
  // const ITEMS_PER_PAGE = 2; // For pagination testing purposes
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [additionalInfo, setAdditionalInfo] =
    useState<category_additional_info>(additionalInformation);

  const pageNumber = Number(getQueryString("page"));
  const [currentPage, setCurrentPage] = useState(pageNumber || 1);

  const totalPages = Math.ceil(products.length / ITEMS_PER_PAGE);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const productList = products.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (!params) {
      // console.log("No params");
    } else {
      if (Number(params?.get("page")) < 1) {
        setCurrentPage(1);
      } else if (Number(params?.get("page")) > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(Number(params?.get("page")));
      }
    }
  }, [totalPages]);

  useEffect(() => {
    // Parsing the normal html content to array. Ul > li to array.
    if (additionalInformation.banner_text_3) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(additionalInformation.banner_text_3 as string, "text/html");
      const listItems = Array.from(doc.querySelectorAll("li"));
      const array = listItems.map((li) => li.textContent?.trim() || "");
      setAdditionalInfo({ ...additionalInfo, bannerText3: array })
    }
  }, [additionalInformation])

  useEffect(() => {
    setCrumbs([{ label: pageContext.name, link: null }]);
  }, [pageContext]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 767px)").matches);
    };

    // Initial check
    handleResize();

    // Set up event listener
    window.addEventListener("resize", handleResize);

    // Clean up listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log(additionalInfo)
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          fetchPriority="high"
          as="image"
          href={additionalInfo.banner_bg_1.node.publicUrl}
          type="image/webp"
        />
        <link
          rel="preload"
          fetchPriority="high"
          as="image"
          href={additionalInfo.banner_mobile_bg_1.node.publicUrl}
          type="image/webp"
        />
      </Helmet>
      <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px]  md:pb-[50px] pb-[30px] contain min-h-[50vh]">
        {/* Category List Banner */}
        <div className="xl:min-h-[200px] lg:min-h-[96px] md:min-h-[96px] mb-1 bg-bg-grey rounded-[21px]">
          {additionalInfo.banner_bg_1 && additionalInfo.banner_text_1 && (

            <div className="list-ban relative xl:min-h-[200px] lg:min-h-[96px] md:min-h-[96px] flex flex-col justify-center ">

              <div className="md:w-[50%] w-[80%] lg:pl-[30px] pl-[18px] z-10 relative ">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: additionalInfo.banner_text_1,
                  }}
                />
              </div>
              <div className="top-0 lg:right-0 right-0 z-[5] xl:w-[77%] w-[100%] h-full absolute">
                {!isMobile
                  ? additionalInfo.banner_bg_1 && (
                    <img
                      src={additionalInfo.banner_bg_1?.node.publicUrl}
                      alt={additionalInfo.banner_bg_1?.node.altText}
                      width={243}
                      height={279}
                      loading="eager"
                      className="w-full h-full object-cover  relative object-right rounded-[21px] ml-auto"
                    />
                  )
                  : // mobile image
                  additionalInfo.banner_mobile_bg_1 && (
                    <img
                      src={additionalInfo.banner_mobile_bg_1?.node?.publicUrl}
                      alt={additionalInfo.banner_mobile_bg_1?.node?.altText}
                      width={243}
                      height={279}
                      loading="eager"
                      className=" w-full h-full object-cover relative object-right rounded-[21px] ml-auto"
                    />
                  )}
              </div>
            </div>
          )}
        </div>

        <div className="mt-[-3px] mb-[-20px]">
          <Breadcrumb crumbs={crumbs} />
        </div>
        {/* Category List Banner */}
        <h1 className="font-sofiaMedium font-medium md:min-h-0 min-h-[50px]  xl:text-[42px] md:text-[32px] text-[32px] text-black">
          {categoryName.replace("&amp;", "&")}
        </h1>
        {/* {loading && <div className=" min-h-[60vh] h-[60vh] flex justify-center items-center"><PageLoader /></div>} */}

        <section className="text-gray-600 body-font min-h-[400px]">
          <div className=" pt-2 pb-3 mx-auto">
            <LazyLoadComponent>
              <div className="flex flex-wrap -m-2 md:gap-y-[25px] gap-y-[12px]">
                {productList?.length > 0 &&
                  productList?.map((record, idx) => {
                    if (idx === 3) {
                      return (
                        <Fragment key={idx}>
                          {additionalInfo.thumbnail_1 &&
                            <div className="lg:w-1/4 sm:w-1/2 w-full md:p-3 p-2  md:rounded-[21px] rounded-[21px] cursor-pointer ">
                              <LazyLoadImage
                                src={additionalInfo.thumbnail_1.node.publicUrl}
                                alt={additionalInfo.thumbnail_1.node.altText}
                                width={330}
                                height={360}
                                loading="eager"
                                className=" w-full h-full lg:h-[387px] md:h-[481px] cat-adv object-cover lg:rounded-[21px] rounded-[21px]"
                              />
                            </div>
                          }
                          <Card
                            record={record}
                            categoryName={categoryName}
                            categorySlug={pageContext.slug}
                          />
                        </Fragment>
                      );
                    }

                    if (idx === 7) {
                      return (
                        <Fragment key={idx}>
                          {additionalInfo.banner_bg_2 && additionalInfo.banner_text_2 && additionalInfo?.banner_text_3 && additionalInfo.banner_image && (additionalInfo.banner_bg_2 || additionalInfo.banner_mobile_bg_2) &&
                            <div className="w-full my-4">
                              <div className="bg-primary rounded-[22px]  lg:py-2 lg:pl-4 cat-ban-txt gap-2 flex lg:flex-row flex-col justify-start items-center relative">
                                <div className=" slg:w-1/3 lg:w-[25%] w-full lg:px-0 lg:pt-0 pt-5 px-4">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: additionalInfo.banner_text_2,
                                    }}
                                  />
                                </div>
                                <div className="slg:w-1/4 lg:w-[25%]  w-full lg:px-0 lg:pt-0 pt-5 px-4">
                                  <ul>
                                    {additionalInfo?.bannerText3?.map(
                                      (item, idx) => {
                                        return (
                                          <li
                                            key={idx}
                                            dangerouslySetInnerHTML={{
                                              __html: item,
                                            }}
                                          />
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                                <div className="slg:w-1/4 lg:w-[25%] w-full ">
                                  <LazyLoadImage
                                    src={additionalInfo.banner_image.node.publicUrl}
                                    // width={245}
                                    // height={211}
                                    alt={additionalInfo.banner_image.node.altText}
                                    className="lg:w-[220px] lg:h-[184px] h-[300px] lg:mx-[unset] mx-auto"
                                  />
                                </div>
                                <div className="lg:top-0 lg:right-0  z-[5] xl:w-[50%] lg:w-[50%] w-[100%] h-full lg:absolute relative  ">
                                  <LazyLoadImage
                                    src={additionalInfo.banner_bg_2.node.publicUrl}
                                    alt={additionalInfo.banner_bg_2.node.altText}
                                    // width={245}
                                    // height={300}
                                    className="w-full h-full lg:block hidden  object-contain  relative object-right rounded-[21px] ml-auto"
                                  />
                                  <LazyLoadImage
                                    src={
                                      additionalInfo.banner_mobile_bg_2.node.publicUrl
                                    }
                                    alt={additionalInfo.banner_mobile_bg_2.node.altText}
                                    width={245}
                                    height={300}
                                    loading="lazy"
                                    className="w-[100%]  h-full object-contain lg:hidden block rounded-b-[21px] object-bottom"
                                  />
                                </div>
                              </div>
                              {/* <img src={banner} alt="banner"  /> */}
                            </div>
                          }
                          {additionalInfo.thumbnail_2 &&
                            <div className="lg:w-1/4 sm:w-1/2 w-full md:p-3 p-2 md:rounded-[21px] rounded-[21px] cursor-pointer ">
                              <LazyLoadImage
                                src={additionalInfo.thumbnail_2.node.publicUrl}
                                alt={additionalInfo.thumbnail_2.node.altText}
                                loading="eager"
                                className=" w-full h-full lg:h-[387px] md:h-[481px] cat-adv object-cover lg:rounded-[21px] rounded-[21px]"
                              />
                            </div>
                          }
                          <Card
                            record={record}
                            categoryName={categoryName}
                            categorySlug={pageContext.slug}
                          />
                        </Fragment>
                      );
                    }

                    return (
                      <Fragment key={idx}>
                        <Card
                          record={record}
                          categoryName={categoryName}
                          categorySlug={pageContext.slug}
                        />
                      </Fragment>
                    );
                  })}
              </div>
            </LazyLoadComponent>
          </div>
        </section>

        {productList?.length > 0 && totalPages > 1 && (
          <div className="pb-4">
            <Pagination
              page={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </div>
      {additionalInfo?.category_rich_description && (
        <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain" >
          <div className="mb-[75px] cat-details"
            dangerouslySetInnerHTML={{
              __html: additionalInfo?.category_rich_description,
            }}
          ></div>
        </div>
      )}
    </>
  );
};

export default List;
